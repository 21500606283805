<template>
  <div
    class="flix-flex flix-flex-list flix-gap-10"
    style="align-items: flex-start"
  >
    <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-10">
      <a
        href="#"
        @click.prevent="setCheck"
        class="flix-html-a flix-text-black flix-flex flix-flex-inline flix-flex-center flix-gap-5"
        style="justify-content: flex-start"
      >
        <icon id="square-empty" v-if="!checked" /><icon
          id="square-empty-check"
          v-if="checked"
        />
        {{ $t("register.terms.title") }}
      </a>
      <div>
        (
        <a
          class="flix-html-a flix-text-black"
          :href="getLink()"
          target="_blank"
          >{{ $t("register.terms.info") }}</a
        >
        )
      </div>
    </div>
    <div
      class="flix-text-danger flix-html-strong flix-html-small"
      v-if="send && !checked"
    >
      {{ $t("register.terms.warning") }}
    </div>
  </div>
</template>
<script>
export default {
  components: {
    icon() {
      return import("@/components/default/icon/");
    }
  },
  props: {
    send: Boolean
  },
  data() {
    return {
      checked: false
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setCheck() {
      this.checked = !this.checked;
    },
    getLink() {
      var link = "https://web.bookingflix.com/";
      if (this.$i18n.locale !== "de") {
        link += this.$i18n.locale + "/";
      }
      link += "terms";

      return link;
    }
  }
};
</script>
<style lang="sass" scoped></style>
